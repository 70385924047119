import React from "react";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <div>
    <h1 className="tac">Our bad...</h1>
    <p className="tac">
      This page doesn't exist, perhaps the <Link to="/">homepage</Link> would be
      more useful?
    </p>
  </div>
);

export default NotFoundPage;
